import React, { Component } from 'react';
import { observer, inject } from "mobx-react";
import { withRouter, NavLink } from "react-router-dom";
import Header from './Header';
import Porfolio from './Porfolio';
let store = null;
var self = null;
@inject("appStore")
@observer
class AboutMe extends React.Component {

    constructor(props) {
        super(props);
    }


    render() {
        return (
            <section class="o-section  t-section  ">

                <div class="o-section__header-bg  t-section__header"></div>
                <div class="o-section__content-bg  t-section__content"></div>

                <div class="o-container">
                    <div class="o-section__container">

                        <header class="o-section__header  t-section__header">
                            <div class="o-content">
                                <h2 class="o-section__heading">
                                    Intro
                                </h2>
                                <div class="o-content__body  o-section__description">
                                    What I am all about.
                                </div>
                            </div>
                        </header>

                        <div class="o-section__content  t-section__content  ">
                            
                            <div class="o-content">
                                <div class="c-intro">
                                    <div class="o-content__body">
                                        <p>
                                        Hi, myself Shoubhik Banerjee, working as a Consultant at Capgemini. since July 2021.
                                            {/* <a href="#" target="_blank">pretium</a>, ligula sollicitudin laoreet viverra, tortor libero sodales leo, eget blandit nunc tortor eu nibh. Nullam mollis. Ut justo. Suspendisse potenti. Nulla vitae mauris non felis mollis faucibus. */}
                                        </p>
                                        <p>
                                        I love to work with latest technologies to solve complex problems of day-to-day life. 
                                        </p>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>

            </section>

        );
    }
}

export default withRouter(AboutMe);