import React, { Component } from 'react';
import { observer, inject } from "mobx-react";
import { withRouter, NavLink } from "react-router-dom";
import RightPanelOptions from './RightPanelOptions';
import Experience from './Experience';
let store = null;
var self = null;
@inject("appStore")
@observer
class CoverProfile extends React.Component {

    constructor(props) {
        super(props);
    }


    render() {
        return (
            <div>
                <div className="crt-sticky" style={{ width: 290, float: 'left' }}>
                <div id="crt-side-box">
                    <div className="crt-side-box-item">
                        <div className="crt-card bg-primary text-center">
                            <div className="crt-card-avatar">
                                <img className="avatar avatar-195" src="assets/images/uploads/avatar/avatar-195x195.png"
                                    srcset="assets/images/uploads/avatar/avatar-390x390-2x.png 2x" width="195"
                                    height="195" alt="" />
                            </div>
                            <div className="crt-card-info">
                                <h2 className="text-upper">Ola Lowe
                                </h2>
                                <p className="text-muted">Florist | Decorator
                                </p>
                                <ul className="crt-social clear-list">
                                    <li>
                                        <a>
                                            <span className="crt-icon crt-icon-facebook">
                                            </span>
                                        </a>
                                    </li>
                                    <li>
                                        <a>
                                            <span className="crt-icon crt-icon-twitter">
                                            </span>
                                        </a>
                                    </li>
                                    <li>
                                        <a>
                                            <span className="crt-icon crt-icon-google-plus">
                                            </span>
                                        </a>
                                    </li>
                                    <li>
                                        <a>
                                            <span className="crt-icon crt-icon-instagram">
                                            </span>
                                        </a>
                                    </li>
                                    <li>
                                        <a>
                                            <span className="crt-icon crt-icon-pinterest">
                                            </span>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="crt-side-box-btn">
                            <a className="btn btn-default btn-lg btn-block btn-thin btn-upper" href="#">Download Resume
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            </div >

        );
    }
}

export default withRouter(CoverProfile);