import React, { Component } from 'react';
import { observer, inject } from "mobx-react";
import { withRouter, NavLink } from "react-router-dom";
import RightPanelOptions from './RightPanelOptions';
let store = null;
var self = null;
@inject("appStore")
@observer
class Education extends React.Component {

    constructor(props) {
        super(props);
    }


    render() {
        return (
            <section class="o-section  t-section  ">

                <div class="o-section__header-bg  t-section__header"></div>
                <div class="o-section__content-bg  t-section__content"></div>

                <div class="o-container">
                    <div class="o-section__container">

                        <header class="o-section__header  t-section__header">
                            <div class="o-content">
                                <h2 class="o-section__heading">
                                    Education
                            </h2>
                                <div class="o-content__body  o-section__description">
                                    Lazy isn&#39;t in my vocabulary.
                            </div>
                            </div>
                        </header>

                        <div class="o-section__content  t-section__content  u-pt-0">

                            <div class="o-content">
                                <div class="a-education-timeline  c-timeline  t-border-color  o-section__full-top-space">
                                    <div class="c-timeline__end  t-border-color"></div>

                                    <div class="c-timeline__item">
                                        <div class="c-timeline__point  t-timeline__point  t-primary-bg"></div>
                                        <div class="o-content">
                                            <div class="o-grid">
                                                <div class="o-grid__col-md-5">
                                                    <div class="c-work__timeframe">
                                                        2013 &ndash; 2017
                                                </div>
                                                    <h3 class="c-work__heading">
                                                        B.Tech Computer Science &amp; Engineering
                                                </h3>
                                                    <h4 class="c-work__title">
                                                        MAKAUT (formely called WBUT)
                                                </h4>
                                                    <div class="c-work__location">
                                                        West Bengal, India
                                                </div>
                                                </div>
                                                <div class="o-grid__col-md-7">
                                                    <p>
                                                        <br></br>
                                                        <h3> DGPA - 8.22</h3>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="c-timeline__item">
                                        <div class="c-timeline__point  t-timeline__point  t-primary-bg"></div>
                                        <div class="o-content">
                                            <div class="o-grid">
                                                <div class="o-grid__col-md-5">
                                                    <div class="c-work__timeframe">
                                                        2011 &ndash; 2013
                                                </div>
                                                    <h3 class="c-work__heading">
                                                        12th , Science
                                                </h3>
                                                    <h4 class="c-work__title">
                                                        CBSE
                                                </h4>
                                                    <div class="c-work__location">
                                                        D.A.V Public School Bokaro, Jharkhand, India
                                                </div>
                                                </div>
                                                <div class="o-grid__col-md-7">
                                                    <p>
                                                        <br></br>
                                                        <h3> % Marks - 74.0</h3>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="c-timeline__item">
                                        <div class="c-timeline__point  t-timeline__point  t-primary-bg"></div>
                                        <div class="o-content">
                                            <div class="o-grid">
                                                <div class="o-grid__col-md-5">
                                                    <div class="c-work__timeframe">
                                                        2010 &ndash; 2011
                                                </div>
                                                    <h3 class="c-work__heading">
                                                        10th
                                                </h3>
                                                    <h4 class="c-work__title">
                                                        ICSE
                                                </h4>
                                                    <div class="c-work__location">
                                                    At Holy Family School, Farakka, West Bengal, India
                                                </div>
                                                </div>
                                                <div class="o-grid__col-md-7">
                                                    <p>
                                                        <br></br>
                                                        <h3> % Marks - 73.0</h3>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>

                    </div>
                </div>

            </section>

        );
    }
}

export default withRouter(Education);