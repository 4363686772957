import React, { Component } from 'react';
import { observer, inject } from "mobx-react";
import { withRouter, NavLink } from "react-router-dom";
import Header from './Header';
import Porfolio from './Porfolio';
import AboutMe from './AboutMe';
import RightPanelOptions from './RightPanelOptions';
import Experience from './Experience';
import CoverProfile from './CoverProfile';
import Education from './Education';
import Contact from './Contact';
let store = null;
var self = null;
@inject("appStore")
@observer
class DownloadAndShare extends React.Component {

    constructor(props) {
        super(props);
    }

    hanldeDownloadProfile = (file_type) => {
        var PDF_FILE_URL = "https://firebasestorage.googleapis.com/v0/b/shoubhik-personal.appspot.com/o/Shoubhik-Banerjee-resume.pdf?alt=media";
        var DOCS_FILE_URL = "https://firebasestorage.googleapis.com/v0/b/shoubhik-personal.appspot.com/o/Shoubhik-Banerjee-resume.docx?alt=media"
        if ((file_type === undefined) || (file_type === null)) {
            this.redirectTo(PDF_FILE_URL)
        } else if (file_type === "pdf") {
            this.redirectTo(PDF_FILE_URL)
        } else if (file_type === "docx") {
            this.redirectTo(DOCS_FILE_URL)
        } else {
            this.redirectTo(PDF_FILE_URL)
        }
    }
    hanldeShareProfile = (source, sub_source = null) => {
        var TITLE = "Shoubhik Banerjee - A MINIMAL FULLSTACK DEVELOPER";
        var URL = "https://shoubhik.dev"
        var SOURCE = URL;
        if (source === "mobile") {
            if (navigator.share) {
                navigator.share({
                    title: TITLE,
                    url: URL
                }).then(() => {
                    console.log('Thanks for sharing!');
                })
                    .catch(console.error);
            } else {
                alert("Sorry direct sharing is not availabe, please copy the link from address bar of your browser")

            }
        } else if (source === "web") {
            if ((sub_source === undefined) || (sub_source === null)) {
                alert("Sorry direct sharing is not availabe, please copy the link from address bar of your browser")
            } else {
                if (sub_source === "facebook") {
                    var fb_url = "https://www.facebook.com/share.php?u=" + URL + "&title=" + TITLE;
                    this.redirectTo(fb_url)
                } else if (sub_source === "twitter") {
                    var twitter_url = "https://twitter.com/intent/tweet?status=" + TITLE + URL
                    this.redirectTo(twitter_url)
                } else if (sub_source === "linkedin") {
                    var linkedin_url = "https://www.linkedin.com/shareArticle?mini=true&url=" + URL + "&title=" + TITLE + "&source=" + URL
                    this.redirectTo(linkedin_url)

                } else if (sub_source === "copy_link") {
                    window.navigator.clipboard.writeText(window.location.href)
                    alert("Link copied to clipboard")

                }
            }

        }
    }

    redirectTo = (url) => {
        window.open(url, "_blank")
    }


    render() {

        var share_profile_ui = null;
        if (window.navigator.share) {
            share_profile_ui = (<button className="btn-download-cv" onClick={(e) => this.hanldeShareProfile("mobile")}><span>Share my profile</span> <i className="fa fa-share" aria-hidden="true" style={{ color: "#e0a80d", marginLeft: 7 }}></i>
            </button>)
        } else {
            share_profile_ui = (<div class="btn_share_profile">
                <span class="share">Share my profile
                <i className="fa fa-share" aria-hidden="true" style={{ color: "#e0a80d", marginLeft: 10 }}></i></span>
                <div class="container_share">
                    <i class="fa fa-facebook-f i-share" onClick={(e) => this.hanldeShareProfile("web", "facebook")}></i>
                    <i class="fa fa-twitter i-share" onClick={(e) => this.hanldeShareProfile("web", "twitter")}></i>
                    <i class="fa fa-linkedin i-share" onClick={(e) => this.hanldeShareProfile("web", "linkedin")}></i>
                    <i class="fa fa-clone i-share" onClick={(e) => this.hanldeShareProfile("web", "copy_link")}></i>
                </div>
            </div>)
        }
        return (
            <div class="o-grid" style={{ textAlign: "center" }}>
                <div class="o-grid__col-md-6  o-grid__col-sm-6">
                    <div class="a-header  o-content">
                        <div class="o-content__body">
                            <div class="btn_share_profile">
                                <span class="share" onClick={(e) => this.hanldeDownloadProfile()}>Download Resume
                <i className="fa fa-download" aria-hidden="true" style={{ color: "#e0a80d", marginLeft: 10 }} ></i></span>
                                <div class="container_share">
                                    <i class="fa fa-file-pdf-o i-share" onClick={(e) => this.hanldeDownloadProfile("pdf")}></i>
                                    <i class="fa fa-file-word-o i-share" onClick={(e) => this.hanldeDownloadProfile("docx")}></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="o-grid__col-md-6  o-grid__col-sm-6">
                    <div class="a-header  o-content">
                        <div class="o-content__body" >
                            {share_profile_ui}
                        </div>
                    </div>
                </div>
            </div>


        );
    }
}

export default withRouter(DownloadAndShare);