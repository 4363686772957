import React, { Component } from 'react';
import { observer, inject } from "mobx-react";
import { withRouter, NavLink } from "react-router-dom";
import Header from './Header';
let store = null;
var self = null;
@inject("appStore")
@observer
class Portfolio extends React.Component {

    constructor(props) {
        super(props);
    }


    render() {
        return (
            <section class="o-section  t-section  ">

                <div class="o-section__header-bg  t-section__header"></div>
                <div class="o-section__content-bg  t-section__content"></div>

                <div class="o-container">
                    <div class="o-section__container">

                        <header class="o-section__header  t-section__header">
                            <div class="o-content">
                                <h2 class="o-section__heading">
                                    Skills
                                </h2>
                                <div class="o-content__body  o-section__description">
                                    Progress bars, anyone?
                                </div>
                            </div>
                        </header>

                        <div class="o-section__content  t-section__content  ">
                            
                            <div class="o-grid">

                                <div class="o-grid__col-sm-6">
                                    <div class="o-content">
                                        <div class="o-media  o-media--block">
                                            <div class="o-media__figure">
                                                <div class="c-number  t-primary-color">
                                                    50<small>%</small>
                                                </div>
                                            </div>
                                            <div class="o-media__body">
                                                <h3>Deep learning frameworks: Torch / Tensorflow</h3>
                                            </div>
                                        </div>
                                        <div class="c-progress-bar  o-content__body  t-border-color-bg  u-mt-text">
                                            <div class="a-progress-bar  c-progress-bar__filler  t-primary-bg" data-percent="50"></div>
                                        </div>
                                    </div>
                                </div>

                                <div class="o-grid__col-sm-6">
                                    <div class="o-content">
                                        <div class="o-media  o-media--block">
                                            <div class="o-media__figure">
                                                <div class="c-number  t-primary-color">
                                                    55<small>%</small>
                                                </div>
                                            </div>
                                            <div class="o-media__body">
                                                <h3>Cloud solutions, IaaS, PaaS, SaaS : AWS / GCP</h3>
                                            </div>
                                        </div>
                                        <div class="c-progress-bar  o-content__body  t-border-color-bg  u-mt-text">
                                            <div class="a-progress-bar  c-progress-bar__filler  t-primary-bg" data-percent="55"></div>
                                        </div>
                                    </div>
                                </div>

                                <div class="o-grid__col-sm-6">
                                    <div class="o-content">
                                        <div class="o-media  o-media--block">
                                            <div class="o-media__figure">
                                                <div class="c-number  t-primary-color">
                                                    70<small>%</small>
                                                </div>
                                            </div>
                                            <div class="o-media__body">
                                                <h3>Chatbot frameworks : Dialogflow / Custom</h3>
                                            </div>
                                        </div>
                                        <div class="c-progress-bar  o-content__body  t-border-color-bg  u-mt-text">
                                            <div class="a-progress-bar  c-progress-bar__filler  t-primary-bg" data-percent="70"></div>
                                        </div>
                                    </div>
                                </div>

                                <div class="o-grid__col-sm-6">
                                    <div class="o-content">
                                        <div class="o-media  o-media--block">
                                            <div class="o-media__figure">
                                                <div class="c-number  t-primary-color">
                                                    65<small>%</small>
                                                </div>
                                            </div>
                                            <div class="o-media__body">
                                                <h3>Backend for REST APIs &amp; Web sockets: Node JS / Python / Php</h3>
                                            </div>
                                        </div>
                                        <div class="c-progress-bar  o-content__body  t-border-color-bg  u-mt-text">
                                            <div class="a-progress-bar  c-progress-bar__filler  t-primary-bg" data-percent="65"></div>
                                        </div>
                                    </div>
                                </div>

                                <div class="o-grid__col-sm-6">
                                    <div class="o-content">
                                        <div class="o-media  o-media--block">
                                            <div class="o-media__figure">
                                                <div class="c-number  t-primary-color">
                                                    80<small>%</small>
                                                </div>
                                            </div>
                                            <div class="o-media__body">
                                                <h3>RDS - Databases : Postgresql / MySql</h3>
                                            </div>
                                        </div>
                                        <div class="c-progress-bar  o-content__body  t-border-color-bg  u-mt-text">
                                            <div class="a-progress-bar  c-progress-bar__filler  t-primary-bg" data-percent="80"></div>
                                        </div>
                                    </div>
                                </div>

                                <div class="o-grid__col-sm-6">
                                    <div class="o-content">
                                        <div class="o-media  o-media--block">
                                            <div class="o-media__figure">
                                                <div class="c-number  t-primary-color">
                                                    50<small>%</small>
                                                </div>
                                            </div>
                                            <div class="o-media__body">
                                                <h3>NoSql - Databases : MongoDb / DynamoDb / Firestore</h3>
                                            </div>
                                        </div>
                                        <div class="c-progress-bar  o-content__body  t-border-color-bg  u-mt-text">
                                            <div class="a-progress-bar  c-progress-bar__filler  t-primary-bg" data-percent="50"></div>
                                        </div>
                                    </div>
                                </div>

                                

                                <div class="o-grid__col-sm-6">
                                    <div class="o-content">
                                        <div class="o-media  o-media--block">
                                            <div class="o-media__figure">
                                                <div class="c-number  t-primary-color">
                                                    75<small>%</small>
                                                </div>
                                            </div>
                                            <div class="o-media__body">
                                                <h3>Cloud Storage : S3 / Buckets</h3>
                                            </div>
                                        </div>
                                        <div class="c-progress-bar  o-content__body  t-border-color-bg  u-mt-text">
                                            <div class="a-progress-bar  c-progress-bar__filler  t-primary-bg" data-percent="75"></div>
                                        </div>
                                    </div>
                                </div>
                                <div class="o-grid__col-sm-6">
                                    <div class="o-content">
                                        <div class="o-media  o-media--block">
                                            <div class="o-media__figure">
                                                <div class="c-number  t-primary-color">
                                                    75<small>%</small>
                                                </div>
                                            </div>
                                            <div class="o-media__body">
                                                <h3>Web Frontend : Bootstrap / React Js</h3>
                                            </div>
                                        </div>
                                        <div class="c-progress-bar  o-content__body  t-border-color-bg  u-mt-text">
                                            <div class="a-progress-bar  c-progress-bar__filler  t-primary-bg" data-percent="75"></div>
                                        </div>
                                    </div>
                                </div>
                                <div class="o-grid__col-sm-6">
                                    <div class="o-content">
                                        <div class="o-media  o-media--block">
                                            <div class="o-media__figure">
                                                <div class="c-number  t-primary-color">
                                                    75<small>%</small>
                                                </div>
                                            </div>
                                            <div class="o-media__body">
                                                <h3>Source control , version control : Git</h3>
                                            </div>
                                        </div>
                                        <div class="c-progress-bar  o-content__body  t-border-color-bg  u-mt-text">
                                            <div class="a-progress-bar  c-progress-bar__filler  t-primary-bg" data-percent="75"></div>
                                        </div>
                                    </div>
                                </div>
                                <div class="o-grid__col-sm-6">
                                    <div class="o-content">
                                        <div class="o-media  o-media--block">
                                            <div class="o-media__figure">
                                                <div class="c-number  t-primary-color">
                                                    65<small>%</small>
                                                </div>
                                            </div>
                                            <div class="o-media__body">
                                                <h3>Android Apps , Hybrid apps : Android Studio / ReactJs</h3>
                                            </div>
                                        </div>
                                        <div class="c-progress-bar  o-content__body  t-border-color-bg  u-mt-text">
                                            <div class="a-progress-bar  c-progress-bar__filler  t-primary-bg" data-percent="65"></div>
                                        </div>
                                    </div>
                                </div>
                                

                            </div>

                        </div>

                    </div>
                </div>

            </section>

        );
    }
}
export default withRouter(Portfolio);