import { observable, computed, action } from "mobx";




export default class AppHelperStore {

    //-------------------------------------Observable Variables---------------------------------
    @observable current_page = "dashboard";
    @observable received_tags = [];
   
    //--------------------------------Action Set Data to Store----------------------------------\
    @action setCurrentPage(page_name) {
        this.current_page = page_name;
    }
    @action setReceivedTags(received_tags) {
        this.received_tags = received_tags;
    }
  

    //--------------------------------Computed Get Data From Store----------------------------------

    @computed
    get getCurrentPage() {
        return this.current_page;
    }
    @computed
    get getReceivedTags() {
        return this.received_tags;
    }
  
}
